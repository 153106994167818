.Directory {
    .Wrapper {
        height: 100vh;
        width: 100vw;
        overflow-y: scroll;

        .WrapperInner {
            margin: 150px 5vw 0 5vw;

            @media only screen and (min-width: 768px) {
                margin: 250px 20vw 0 20vw;
            }   
           
        }

        .VideoWrapper {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;

            display: flex;
            justify-content: center;
            align-content: center;

            pointer-events: none;

            video {
                width: 80vw;
                height: 80vh;
                opacity: .25;
                border-radius: 8px;
                overflow: hidden;
                object-fit: cover;
                align-self: center;
            }
        }

        .Welcome {
            position: relative;
            transform: translate3d(0, 0, 0);
            transition: transform, opacity, cubic-bezier(0.61, 1, 0.88, 1) .2s;
            margin-top: 20vh;
            user-select: none;
            margin-bottom: 35px;

            width: 90%;

            p{
                color: var(--colorOffWhite);
            }

            .Accent {
                width: 30px;
                height: 2px;
                background-color: var(--colorCocoaLight);
            }

            // opacity: 0;
            animation: initialBuildIn cubic-bezier(0.61, 1, 0.88, 1) .2s;
        }

        .SearchContainer {
            transform: translate3d(0, 0, 0);
            transition: transform cubic-bezier(0.61, 1, 0.88, 1) .2s; 
            
            animation: initialBuildIn cubic-bezier(0.61, 1, 0.88, 1) .2s;
        }

        &[data-searchmode="true"] {
            .Welcome {
                opacity: .3;
                transform: translateY(-13vh) scale(.95) !important;
            }

            .SearchContainer {
                transform: translateY(-16vh);
            }
        }

        .CategoryHotlinksContainer {
            position: relative;
            width: 100%;
            margin-top: 25px;
            mask-image: linear-gradient(to right, black 96%, transparent 100%);

            @media only screen and (min-width: 768px) {
                mask-image: none;
            }

            
            &[data-active="true"] {
                display: none;
            }

            .CategoriesEyebrow {
                width: 100px;
                display: flex;

                h4{
                    color: white;
                    opacity: 0.6;
                }
                
            }

            .CategoryLinksContainer {
                display: flex;
                flex-direction: row;
                overflow-x: scroll;
                scroll-snap-type: x mandatory;

                @media only screen and (min-width: 768px) {
                    display: grid;
                    grid-template-columns: 1fr 1fr;
                }
            }

            .FeaturedLinksContainer {
                display: flex;
                flex-direction: row;
                overflow-x: scroll;
                scroll-snap-type: x mandatory;

                @media only screen and (min-width: 768px) {
                    display: grid;
                    grid-template-columns: 1fr 1fr;
                }

                span{
                    min-width: 280px;
                    height: 130px;
                }
            }

            opacity: 0;
            animation: initialBuildIn cubic-bezier(0.61, 1, 0.88, 1) .2s .1s forwards;

            @keyframes initialBuildIn {
                from {
                    opacity: 0;
                    transform: translateY(30px);
                }

                to {
                    opacity: 1;
                    transform: translateY(0px);
                }
            }

            span {
                scroll-snap-align: start;
                display: flex;
                justify-content: flex-start;
                align-items: center;
                background-color: var(--colorOffWhite);
                border: 1px solid rgba(0, 0, 0, .3);
                border-radius: 6px;
                margin: 4px;
                padding: 12px;
                min-width: 160px;
                //height: 70px;
                font-size: 12px;
                color: var(--colorDarkGray);

                word-break: break-all;
                user-select: none;

                &:hover {
                    color: var(--colorCaramel);
                    cursor: pointer;
                }

                &:active {
                    transform: scale(.99);
                    color: var(--colorMidGray);
                }

                img{
                    width: 50px;
                    height: 50px;
                    object-fit: contain;
                    margin-right: 15px;
                }
            }
        }

        .FooterContainer{
            position: relative;
            width: 100%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            margin-bottom: 150px;

            a{
                width: 200px;
                padding: 14px;
                background-color: var(--colorDarkGray);
                border-radius: 8px;
                color: var(--colorCocoaLight);
                text-align: center;
                text-decoration: none;
            }

            footer{
                color: var(--colorOffWhite);
            }
        }
    }
}