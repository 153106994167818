.SearchUnit{
    display: flex;
    width: 100%;
    margin-top: 25px;
    flex-direction: column;

    .SearchBarContainer{
        background-color: var(--colorOffWhite);
        height: 45px;
        padding: 8px;
        border-radius: 4px;

        display: flex;
        flex-direction: row;

        select{
            border: none;
            height: 100%;
            appearance: none;
            text-align: center;
            padding: 8px;
            width: 30%;
            margin-right: 2vw;

            color: var(--colorCaramel);
            font-weight: bolder;
            background: #FFFFFF;
            border: 1px solid rgba(255,255,255,0.13);
            box-shadow: 0 0 5px 0 #DADADA;
            border-radius: 4px;
            font-size: 12px;

            text-overflow: ellipsis;
        }

        input{
            width: 100%;
            border: none;
            height: 100%;
            padding: 0;
            margin: 0;
            outline: none;
            background-color: transparent;
            font-size: 14px;
            margin-left: 5px;
        }
    }

    .ResultsContainer{
       
        overflow-y: scroll;

        .ResultsEyebrow{
            position: relative;
            width: 100%;
            display: flex;
            justify-content: flex-end;
            color: white;

            h4{
                color: var(--colorOffWhite);
                opacity: .6;
            }
        }

        .NoResultsPlaceHolder{
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            text-align: center;
            padding: 25px;
            margin-top: 25px;
            background-color: var(--colorOffWhite);
            min-height: 100px;
            border-bottom: 1px solid var(--colorLighterGray);
            border-radius: 6px;
            // color: var(--colorMidGray);
        }

        h2{
            color: white;
        }
        
    }
}