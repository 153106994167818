.SearchResult{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 18px;
    background-color: var(--colorOffWhite);
    min-height: 100px;
    border-bottom: 1px solid var(--colorLighterGray);

    .Name{
        display: inline;
        color: var(--colorDarkGray);
    }

    .Location{
        margin-top: 5px;
        display: block;
        font-size: 11px;
        color: var(--colorCocoa);
    }
    
    .Title{
        display: inline;
        background-color: white;
        border-radius: 4px;
        color: var(--colorCaramel);
        font-size: 11px;
        padding: 4px;
        margin: 2px;
        margin-left: 8px;
    }

    &:first-of-type{
        border-radius: 6px 6px 0 0;
    }

    &:nth-of-type(2){
        border-radius: 6px 6px 0 0;
    }
    

    &:last-of-type{
        border-radius: 0 0 6px 6px;
    }

    .SearchInfo{
        padding-right: 10px;
    }

    .CTAContainer{
        display: flex;
        align-items: center;
        border-left: 1px solid var(--colorLightGray);

        img{ 
            width: 35px; 
            margin-right: 10px;
            margin-left: 30px;
        }
    }
}