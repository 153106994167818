
html{
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: bolder;
  background-color: black;
}

html,
body {
  height: 100%;
  width: 100%;
  overflow: auto;
}

h1,h2,h3,h4,h5{ 
  font-weight: bolder;
}

h1{
  font-size: 28px;
  color: var(--colorLightGray);
  font-weight: bolder;
  letter-spacing: -1px;
  line-height: 30px;

  @media only screen and (min-width: 768px) {
    font-size: 42px;
    line-height: 50px;
  }
}

h2{
  font-size: 18px;
  color: var(--colorCaramel);
}

h3{
  font-size: 16px;
  color: var(--colorDarkGray);
}

h4{
  font-size: 14px;
  color: var(--colorCaramel);
  margin-bottom: 10px;
  margin-top: 10px;
}

p{
  font-weight: normal;
  color: var(--colorMidGray);
  font-size: 12px;
  line-height: 20px;
}

a{ 
  font-size: 12px;
  color: var(--colorCaramel);
}

a {color: var(--colorCaramel); }
a:visited {color: var(--colorCaramel); }
a:active {color: var(--colorCaramel); }


.App {
  position: absolute;
  top: 0;
  bottom: 0;
  overflow: hidden;
  width: 100%;
}

:root {
  --colorDarkGray: #232323;
  --colorMidGray: #717171;
  --colorLightGray: #D0D0D0;
  --colorCocoa: #7E5036;
  --colorCocoaLight: #C5A47B;
  --colorCocoaLightest: #E4D6C7;
  
  --colorCaramel: #B58C58;
  --colorOffWhite: #F4F4F4;
}