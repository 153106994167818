.About{
    background-color: var(--colorCocoaLightest);
    margin: 50px 5vw 50px 5vw;
    padding: 8vw;

    @media only screen and (min-width: 768px) {
        margin: 150px 20vw 50px 20vw;
    } 

    h1{
        color: var(--colorCocoa);
    }

    p{
        color: var(--colorMidGray);
    }

    .Accent {
        width: 30px;
        height: 2px;
        background-color: var(--colorCocoa);
    }

    opacity: 0;
    animation: initialBuildIn cubic-bezier(0.61, 1, 0.88, 1) .3s .3s forwards;

    @keyframes initialBuildIn {
        from {
            opacity: 0;
            transform: translateY(30px);
        }

        to {
            opacity: 1;
            transform: translateY(0px);
        }
    }
}