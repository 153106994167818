.OtSpinner {
  display: inline-block;
  position: relative;
  width: 55px;
  height: 55px;
  align-self: center;
}
.OtSpinner div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 40px;
  height: 40px;
  margin: 8px;
  border: 3px solid var(--colorOffWhite);
  border-radius: 50%;
  animation: OtSpinner 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: var(--colorOffWhite) transparent transparent transparent;
}
.OtSpinner div:nth-child(1) {
  animation-delay: -0.45s;
}
.OtSpinner div:nth-child(2) {
  animation-delay: -0.3s;
}
.OtSpinner div:nth-child(3) {
  animation-delay: -0.15s;
}
@keyframes OtSpinner {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}